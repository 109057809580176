import React from 'react'

export default function Hamburger({isOpen}) {
    return (
        <>
        <div className="hamburger">
            <div className="burger burger1"></div>
            <div className="burger burger2"></div>
            <div className="burger burger3"></div>
        </div>

        <style>{` 

           .burger1 {
            opacity: ${ isOpen ? 0 : 1};
           }

           .burger3 {
            opacity: ${ isOpen ? 0 : 1};
          }
        `}
        </style>
        </>
    )
}